import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "ReactVR/react-360 is great, but maybe not quite there yet",
  "description": "",
  "date": "2018-08-03T08:00:00.000Z",
  "published": "2018-08-03T08:00:00.000Z",
  "image": ""
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><em parentName="p">{`This exploration will feed into a new VR/3D chapter in my React dataviz book. `}<a parentName="em" {...{
          "href": "https://gumroad.com/l/pMtnZ"
        }}>{`Preorder it now 👉`}</a></em></p>
    <p>{`My goal was to build a 3D scatterplot that you can explore. Stand inside your data and look around.`}</p>
    <p>{`Wouldn't that be cool?`}</p>
    <p>{`Instead, I got a squished `}<a parentName="p" {...{
        "href": "http://disney.wikia.com/wiki/Baymax"
      }}>{`Baymax healthcare companion`}</a>{`. 🤔`}</p>
    <p><img parentName="p" {...{
        "src": "https://swizec.com/blog/wp-content/uploads/2018/08/CleanShot_2018-08-03_at_09.01.09-1.png",
        "alt": null
      }}></img></p>
    <p>{`ReactVR, recently renamed to `}<a parentName="p" {...{
        "href": "https://github.com/facebook/react-360"
      }}>{`react-360`}</a>{`, doesn't have 3D modeling primitives like spheres and boxes. Instead, it asks you to import 3D models and build your scenes with that.`}</p>
    <p>{`The base example is a 3D viewer of models from `}<a parentName="p" {...{
        "href": "https://poly.google.com/"
      }}>{`Google's Poly library`}</a>{`.`}</p>
    <p>{`And that works great 👇`}</p>
    <lite-youtube {...{
      "videoid": "49wBihodcso",
      "videostartat": "0"
    }}></lite-youtube>
    <p>{`You can import a model, display it with a React component, and look around using VR goggles and the Oculus browser. Just gotta make sure there's a URL that shows your VR app.`}</p>
    <h2 {...{
      "id": "to-display-a-3d-model"
    }}>{`To display a 3D model…`}</h2>
    <p>{`To display a 3D model in VR space, you put it in a react-360 `}<inlineCode parentName="p">{`<View>`}</inlineCode>{` component, add some light, and an `}<inlineCode parentName="p">{`<Entity>`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`class Baymax extends React.Component {
  render() {
    return (
      <view>
        <ambientlight intensity={1.0} color="#ffffff">
          <pointlight
            intensity={0.4}
            style={{ transform: [{ translate: [0, 4, -1] }] }}
          >
            <entity source={{ obj: baymax }}></entity>
          </pointlight>
        </ambientlight>
      </view>
    );
  }
}
`}</code></pre>
    <p>{`Lighting doesn't do much in my `}<inlineCode parentName="p">{`<Baymax>`}</inlineCode>{` example. I'm not sure why. Maybe because the whole model is white 🤔`}</p>
    <p>{`Where it gets interesting is the scale transformation. `}<em parentName="p">{`In theory`}</em>{`, you can scale your models on all 3 axes. In practice, the `}<inlineCode parentName="p">{`scaleZ`}</inlineCode>{` transform is currently not implemented and throws an error. Even though it's in the docs. Oops.`}</p>
    <p>{`But I guess that's the problem with models you import from the web because you don't know how to make your own. Their scales are out of whack.`}</p>
    <p>{`The Baymax model, for example, looks great when it's 200 meters away. Yes, units for all vectors in ReactVR and react-360 are in meters. Love it.`}</p>
    <p><img parentName="p" {...{
        "src": "https://swizec.com/blog/wp-content/uploads/2018/08/nonsquished.png",
        "alt": "Baymax 200 meters away head on"
      }}></img></p>
    <h2 {...{
      "id": "to-position-a-react-360-view"
    }}>{`To position a react-360 view…`}</h2>
    <p>{`Positioning react-360 views happens through something they call roots. You can think of it as an anchor point for your components that you build on top of.`}</p>
    <p>{`This goes in your `}<inlineCode parentName="p">{`client.js`}</inlineCode>{` and you can have as many roots as you need.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`r360.renderToLocation(r360.createRoot("Baymax"), new Location([0, -30, -200]));
`}</code></pre>
    <p>{`Location is a 3D vector based off of the user's position. In this case, that's `}<inlineCode parentName="p">{`0`}</inlineCode>{` meters to the left or right, `}<inlineCode parentName="p">{`30`}</inlineCode>{` meters down, and `}<inlineCode parentName="p">{`200`}</inlineCode>{` meters back. Go `}<inlineCode parentName="p">{`200`}</inlineCode>{` meters in the other direction, and your user has to turn their head to see your scene.`}</p>
    <p>{`And that's where react-360 starts to shine. 360 degrees on 3 axes is a lot of space to play around with. Seriously. A `}<em parentName="p">{`ton`}</em>{` of space. You can't even imagine until you start to play around.`}</p>
    <h2 {...{
      "id": "react-360-shines-at-placing-2d-views-in-3d-space"
    }}>{`react-360 shines at placing 2D views in 3D space`}</h2>
    <p>{`What I believe react-360 is truly optimized for is putting 2D panels in 3D space and giving your users more room to use different controls.`}</p>
    <p><img parentName="p" {...{
        "src": "https://swizec.com/blog/wp-content/uploads/2018/08/CleanShot_2018-08-03_at_09.23.27-1.png",
        "alt": null
      }}></img></p>
    <p>{`It's kind of a shame that this is the case, but there's a lot you can do with 2D in 3D. Imagine a data dashboard for your infrastructure that uses full 3-axis 360 views to place charts all around you instead of trying to squish everything into a small browser window.`}</p>
    <p>{`I don't know about you, but I always run out of space when making monitoring dashboards 😅`}</p>
    <p>{`Code looks just like React Native by the way. You have `}<inlineCode parentName="p">{`<View>`}</inlineCode>{`s and `}<inlineCode parentName="p">{`<Text>`}</inlineCode>{`s and `}<inlineCode parentName="p">{`<Image>`}</inlineCode>{`s and `}<inlineCode parentName="p">{`<VrButton>`}</inlineCode>{`s. You put them together and voila, an interface.`}</p>
    <p>{`This builds that panel on the left 👇`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const TopPosts = props => {
    if (!props.posts) {
        return (
            <view style={styles.wrapper}>
                <text>Loading...</text>
            </view>
        );
    }

    return (
        <view style={styles.wrapper}>
            <text style={styles.postButtonName}>
                Posts: {props.posts.length}
            </text>
            {props.posts.map((post, i) => (
                <postbutton key={post.id} index={i} name={post.name} author={post.author} preview={post.preview}>
            ))}
        </postbutton></view>
    );
};
`}</code></pre>
    <p>{`Take a list of posts from Google's Poly store, render a `}<inlineCode parentName="p">{`<View>`}</inlineCode>{`, put some `}<inlineCode parentName="p">{`<Text>`}</inlineCode>{` inside to know the count, then iterate through the data and render a `}<inlineCode parentName="p">{`<PostButton>`}</inlineCode>{` for each entry.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`<PostButton>`}</inlineCode>{` component is a `}<inlineCode parentName="p">{`<VrButton>`}</inlineCode>{` that gives us clickability and callbacks. It contains an image and two text labels. Pretty neat.`}</p>
    <p>{`You place the panel in 3D space with a root, a surface, and a render to surface.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const leftPanel = new Surface(300, 1200, Surface.SurfaceShape.Flat);
leftPanel.setAngle(-0.6, 0);
r360.renderToSurface(r360.createRoot("TopPosts"), leftPanel);
`}</code></pre>
    <p>{`The extreme 1200 height doesn't look so great in a web browser, but works great in VR goggles because you can look up and down.`}</p>
    <p>{`360 degree really is a lot of room for activities. Really. Wish I knew how to record what I can see inside my goggles.`}</p>
    <h2 {...{
      "id": "fin"
    }}>{`Fin`}</h2>
    <p>{`There's a lot of potential here. In production mode, react-360 is fast enough to avoid nausea; in dev, not so much. The engineering UX is similar to React Native, and VR is growing like crazy.`}</p>
    <p>{`You might not realize it, but there's `}<em parentName="p">{`a lot`}</em>{` of VR users out there. 171,000,000 according to `}<a parentName="p" {...{
        "href": "https://www.statista.com/statistics/426469/active-virtual-reality-users-worldwide/"
      }}>{`statista`}</a>{`. Similar to the web in 1999.`}</p>
    <p><em parentName="p">{`Now`}</em>{`'s the time to start playing around 😉`}</p>
    <p>{`Watch me build the squished Baymax 👇 and `}<a parentName="p" {...{
        "href": "#"
      }}>{`preorder React + D3 2018`}</a>{`, likely the first React book/course to include VR stuff 😛`}</p>
    <lite-youtube {...{
      "videoid": "aGN9Nm-bHYs",
      "videostartat": "0"
    }}></lite-youtube>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      